<template>
  <!--
    x-vue-csv-supported: false
    x-vue-table-show: true
    fields: [id, spaceName, created, name, key, description, fieldType, appliesTo, constraints]
    x-vue-custom-fields: [ ]
    x-vue-tags: [ ]
    x-vue-metadata: [ ]
    -->
  <CIDataTable
      :vuex="vuex"
      :settings="settings"
      :route="route"
      model="customFields"
  />
</template>
<script>
  import { customFields } from '@/config/descriptions/customFields';
  import { customFieldsTexts } from '@/config/pageTexts/customFields.json';

  export default {
    name: 'CustomFieldsTable',
    data() {
      return {
        vuex: {
          getter: {
            theme: 'theme/theme',
            data: 'customFields/customFields',
            fields: 'customFields/fields',
            sortableFields: 'customFields/sortableFields',
            pages: 'customFields/pages',
            loading: 'customFields/loading',
            resultCount: 'customFields/resultCount',
            totalRecords: 'customFields/totalRecords',
            originalFields: 'customFields/originalFields',
            searchableAllFields: 'customFields/searchableAllFields',
            success: 'customFields/success',
            message: 'customFields/message',
          },
          actions: {
            fields: 'customFields/handleFields',
            delete: 'customFields/handleDeleteCustomFields',
            reset: 'customFields/handleClearFilter',
            query: 'customFields/handleGetCustomFieldsByQuery',
          },
        },
        settings: {
          describe: { ...customFields },
          page: { ...customFieldsTexts },
        },
        route: {
          create: 'CreateCustomField',
          edit: 'EditCustomField',
          preview: 'PreviewCustomField',
        },
      };
    },
  };
</script>