<template>
  <CustomFieldsTable/>
</template>
<script>
import CustomFieldsTable from '@/generated/ziqni/views/customFields/CustomFieldsTable';

export default {
  components: {
    CustomFieldsTable
  },
  props: {}
};
</script>